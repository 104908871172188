<script context="module" lang="ts">
	// const mapURL = "https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-s+000000(115.87237654669232,-32.123105366399635),pin-s+000000(115.78591036797296,-32.06203270690259)/[115.6095,-32.15,116.0489,-32.01]/1280x320@2x?access_token=pk.eyJ1IjoicGlrbGVzIiwiYSI6ImNrenFkdXcyMzA1Z2IzMHBhMWxpODkxOHkifQ.lTuaukROGCBhybcBlCstXA";
	import mapURL from './map@2x.png';
</script>

<!--	accessToken="pk.eyJ1IjoicGlrbGVzIiwiYSI6ImNrY2N6dXY3MzA5dWYyem1zNWIwajhtdGQifQ.4p4-WplGq5cdyvupaJAoNg"-->
<!--		center: [115.83811, -32.09932],-->
<!--		logoPosition: 'top-right',-->
<!--		pitch: 65,-->
<!--		bearing: 42.7,-->
<!--		zoom: 12-->
<!--	<Marker lat={-32.06203270690259} lng={115.78591036797296} label="O'Connor" />-->
<!--	<Marker lat={-32.123105366399635} lng={115.87237654669232} label="Jandakot" />-->

<img
	src={mapURL}
	class="h-[24rem] w-full rounded-md object-cover object-bottom"
	alt="A birds-eye view map of Perth, with a marker for both our O'Connor and Jandakot location."
/>
