import { apiUrl } from '$lib/settings/api';

export const prerender = true;

/** @type {import('./$types').PageLoad} */
export async function load({ params }) {
	const url =
		apiUrl +
		'news/news/index?limit=3&filter=[{"field":"postStatus","operation":"eq","value":"published"}]';
	const res = await fetch(url);
	const apiResponse = await res.json();
	const posts = apiResponse['data']['documents'];

	return {
		posts: posts
	};
}
