<script context="module" lang="ts">
	import MetaInfo from '$lib/components/base/meta-info.svelte';
	import MetaTags from '$lib/components/utils/MetaTags.svelte';

	import InlineButton from '$lib/components/base/inline-button.svelte';
	import LocationMap from '$lib/components/maps/LocationMap.svelte';
	import NewsSummary from '$lib/components/news/NewsSummary.svelte';
	import ArrowNext from 'iconoir/icons/arrow-right-circle.svg';

	import logoWhite from '$lib/img/branding/logos/active_circle_white.png';

	import photoComp1 from '$lib/img/general/active-graphic-comp-1.jpg';
	import photoNinja1 from '$lib/img/general/active-graphic-ninja-1.jpg';
	import photoKindy1 from '$lib/img/general/kindy-1.jpg';
	import photoHeaderBG from '$lib/img/graphics/header-bg.jpg';

	import icoAdd from 'iconoir/icons/plus-circle.svg';
	import icoArrowRight from 'iconoir/icons/arrow-right.svg';

	import getPermissions from '$lib/components/auth/getPermissions';

	async function canCreate() {
		return await getPermissions('create-news');
	}
</script>

<script lang="ts">
	/** @type {import('./$types').PageData} */
	export let data;

	const posts = data.posts;
</script>

<svelte:head>
	<title>Active Gymnastics</title>
</svelte:head>

<MetaTags title="Active Gymnastics" />

<!-- Mobile -->
<div id="mobileview" class="md:hidden">
	<header>
		<div class="bg-[#F02A25] pb-4">
			<div id="header-content" class="px-8 pt-8">
				<img src={logoWhite} alt="Active Gymnastics Logo" class="m-auto h-64 w-64" />
				<h1 class="mb-2 mt-8">
					<span class="text-4xl font-light text-white">Gymnastics for every<b>body</b>.</span>
				</h1>
				<div class="text-white">
					<MetaInfo />
				</div>
			</div>
		</div>
		<svg viewBox="0 0 1440 114.212" class="-mt-0.5 mb-0.5 w-full">
			<path
				fill="#F02A25"
				fill-opacity="1"
				d="M 0 58.767 L 80 74.767 C 160 90.767 320 122.767 480 112.067 C 640 101.767 800 47.767 960 32.067 C 1120 15.767 1280 37.767 1360 48.067 L 1440 58.767 L 1440 0 L 0 0 L 0 58.767 Z"
			/>
		</svg>
	</header>

	<section class="mx-4 mt-4">
		<div class=""><h2 class="text-center text-2xl">Latest from Active</h2></div>
		<NewsSummary />
	</section>

	<section class="mx-4 mt-4">
		<div class="grid grid-cols-1 gap-x-4 gap-y-4 md:grid-cols-2">
			<a href="/dash">
				<div class="rounded-full bg-red-600 py-2 text-center drop-shadow-sm">
					<p class="text-white">Book Now</p>
				</div>
			</a>
			<a href="/programs">
				<div class="rounded-full border-[1px] border-red-600 py-2 text-center drop-shadow-sm">
					<p class="text-red-600">View Programs</p>
				</div>
			</a>
		</div>
	</section>

	<div class="mt-4">
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" class="w-full">
			<path
				fill="#ffffff"
				fill-opacity="1"
				d="M0,96L80,96C160,96,320,96,480,112C640,128,800,160,960,176C1120,192,1280,192,1360,192L1440,192L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
			/>
		</svg>
		<div class="mx-4 -mt-8">
			<p class="text-sm font-semibold uppercase text-red-600">Why Active</p>
			<h3 class="text-lg">The Active Difference</h3>
		</div>
		<section class="-mt-5 bg-white pb-4 pt-6 dark:bg-zinc-800">
			<div class="mx-4 text-zinc-900 dark:text-zinc-50">
				<div class="mb-4">
					<p>
						At Active, we know that gymnastics is more than just a sport - it's an exciting way for
						kids to develop physical skills that can be applied to any sport. Our coaches make sure
						that our gym is a fun, safe, and healthy environment for kids of all ages.
					</p>
					<br />
					<p>
						We have programs for children as young as 18 months, and our progressive recreational
						program is perfect for kids of all ages. In addition, we offer a leading WAG competitive
						program, as well as classes for teens and adults who want to keep active and have fun.
					</p>
				</div>
				<InlineButton
					buttonText="Find Your Class"
					href="/dash"
					icon={ArrowNext}
					iconAlt="Navigation: Next Arrow Icon"
					iconStyle="filter: invert(25%) sepia(65%) saturate(3637%) hue-rotate(346deg) brightness(86%) contrast(100%);"
				/>
				<div class="mt-4">
					<img
						src={photoComp1}
						class="m-auto max-h-[24rem] w-full max-w-[24rem]"
						alt="Three young kids crawling through a foam cylinder looking at camera."
					/>
				</div>
				<div class="mt-4" />
				<div class="mb-4">
					<p class="text-sm font-semibold uppercase text-red-600">About Us</p>
					<h3 class="text-lg">Our Location</h3>
					<p>
						We are proud to offer two state-of-the-art facilities in Perth. Active's two premises
						are fitted with state-of-the-art equipment, providing a safe & fun environment with
						plenty of equipment, so children are always on the move.
					</p>
					<br />
					<p>
						Our O'Connor location, located just ten minutes east of Fremantle, is a massive
						purpose-built facility spanning over 1700 sqm of space. It offers an in-ground pit and
						plenty of equipment. Our Jandakot facility, located just minutes from Cockburn Central,
						spans 1000 sqm and offers an above-ground pit.
					</p>
				</div>
				<InlineButton
					buttonText="Book Now"
					href="/dash"
					icon={ArrowNext}
					iconAlt="Navigation: Next Arrow Icon"
					iconStyle="filter: invert(25%) sepia(65%) saturate(3637%) hue-rotate(346deg) brightness(86%) contrast(100%);"
				/>
				<div>
					<img
						src={photoKindy1}
						class="m-auto my-2 max-h-[24rem] w-full max-w-[24rem]"
						alt="Three young kids crawling through a foam cylinder looking at camera."
					/>
				</div>
				<div>
					<p class="text-sm font-semibold uppercase text-red-600">The Sport</p>
					<h3 class="text-lg">Why Gymnastics</h3>

					<p>
						Gymnastics is more than just a sport - it's a fun and engaging way for kids to develop a
						wide range of physical and mental skills. By practicing balance, coordination, strength,
						and flexibility, children who participate in gymnastics can enhance their fine and gross
						motor skills, advance their social and intellectual abilities, and improve their body
						and spatial awareness. This, in turn, can help them succeed across a wide range of
						physical activities and sports.
					</p>
					<br />
					<p>
						Gymnastics has many significant advantages. One of the most valuable is the increased
						understanding of fundamental movement skills. Not only are these skills essential for
						healthy growth and development, but they are also an essential element in building solid
						foundations in a child’s life.
					</p>
				</div>
				<div class="mt-2" />
				<InlineButton
					buttonText="Join the Active Family"
					href="/dash"
					icon={ArrowNext}
					iconAlt="Navigation: Next Arrow Icon"
					iconStyle="filter: invert(25%) sepia(65%) saturate(3637%) hue-rotate(346deg) brightness(86%) contrast(100%);"
				/>
				<div>
					<img
						src={photoNinja1}
						class="m-auto my-2 max-h-[24rem] w-full max-w-[24rem]"
						alt="Three young kids crawling through a foam cylinder looking at camera."
					/>
				</div>
			</div>
		</section>
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" class="w-full">
			<path
				fill="#ffffff"
				fill-opacity="1"
				d="M0,64L80,80C160,96,320,128,480,122.7C640,117,800,75,960,53.3C1120,32,1280,32,1360,32L1440,32L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
			/>
		</svg>
	</div>
</div>

<!-- Desktop -->
<div class="hidden md:block">
	<!--Header-->
	<div
		class="-mt-24 bg-zinc-800 pb-16 pt-24"
		style="background-image: url({photoHeaderBG}); background-size: cover; background-position: center center;"
	>
		<div class="mt-8" />
		<img src={logoWhite} alt="Active Gymnastics Logo" class="m-auto h-48" />
		<h1 class="mt-12 text-center text-5xl text-white">
			Gymnastics for<br />every<span class="font-semibold">body.</span>
		</h1>
		<div class="mt-8 flex flex-wrap justify-center gap-x-4">
			<a href="/dash">
				<button
					class="w-64 rounded-sm border-2 border-white bg-white px-8 py-2 text-red-600 transition-all duration-200 ease-in-out hover:border-transparent hover:bg-[rgba(255,255,255,0.8)]"
				>
					Book Now
				</button>
			</a>
			<a href="/programs">
				<button
					class="w-64 rounded-sm border-2 border-white bg-transparent px-8 py-2 text-white transition-all duration-200 ease-in-out hover:bg-[rgba(255,255,255,0.2)]"
				>
					View Programs
				</button>
			</a>
		</div>
	</div>

	<!--Content-->
	<div class="bg-neutral-50">
		<div
			class="m-auto grid max-w-screen-2xl grid-cols-3 divide-x divide-black py-4 text-center font-light"
			style="font-family: 'Ubuntu', sans-serif"
		>
			<div>
				<p>0417503517</p>
			</div>
			<div>
				<p>O'Connor & Jandakot</p>
			</div>
			<div>
				<p>info@activegym.com.au</p>
			</div>
		</div>
	</div>

	<div class="bg-white pb-8 md:px-4 lg:px-8">
		<div class="m-auto max-w-screen-2xl pt-4">
			<section>
				<div>
					<p class="font-semibold uppercase text-red-600">Today</p>
					<h2 class="text-3xl">Latest News</h2>
				</div>
				{#await canCreate()}
					<div />
				{:then result}
					{#if result == true}
						<div class="mb-4 w-fit rounded-full bg-zinc-900 px-4 py-1">
							<a href="/news/create/">
								<p class="inline-block text-white">Add News</p>
								<img
									class="inline-block w-6"
									src={icoAdd}
									alt="Add Program"
									style="filter: invert(94%) sepia(25%) saturate(0%) hue-rotate(354deg) brightness(103%) contrast(102%);"
								/>
							</a>
						</div>
					{/if}
				{/await}
				<div class="mt-4 grid gap-x-8 md:grid-cols-2 lg:grid-cols-3">
					{#each posts as newsPost}
						<article>
							<a href="/news/{newsPost._id}">
								<img
									src={newsPost.postImgLink}
									alt={newsPost.postImgAlt}
									class="h-64 w-full rounded-md object-cover"
									style="object-position: {newsPost.postImgFocal}"
								/>
								<h3 class="mt-1 h-[1.75rem] text-lg">{newsPost.postTitle}</h3>
								<p class="mb-2">{newsPost.postBody.substring(0, 130)}...</p>
								<InlineButton
									buttonText="Read More"
									href="/news/{newsPost._id}"
									icon={ArrowNext}
									iconAlt="Navigation: Next Arrow Icon"
									iconStyle="filter: invert(25%) sepia(65%) saturate(3637%) hue-rotate(346deg) brightness(86%) contrast(100%);"
								/>
							</a>
						</article>
					{/each}
				</div>
			</section>
			<div id="section-b" class="mt-8 grid grid-cols-2 items-center gap-8">
				<div>
					<p class="uppercase text-red-600">Why Active</p>
					<h3 class="text-lg">The Active Difference</h3>
					<p>
						At Active, we know that gymnastics is more than just a sport - it's an exciting way for
						kids to develop physical skills that can be applied to any sport. Our coaches make sure
						that our gym is a fun, safe, and healthy environment for kids of all ages.
					</p>
					<br />
					<p>
						We have programs for children as young as 18 months, and our progressive recreational
						program is perfect for kids of all ages. In addition, we offer a leading WAG competitive
						program, as well as classes for teens and adults who want to keep active and have fun.
					</p>
					<div class="mt-2" />
					<InlineButton
						buttonText="Book Now"
						href="/dash"
						icon={ArrowNext}
						iconAlt="Navigation: Next Arrow Icon"
						iconStyle="filter: invert(25%) sepia(65%) saturate(3637%) hue-rotate(346deg) brightness(86%) contrast(100%);"
					/>
				</div>

				<div>
					<img
						src={photoComp1}
						class="m-auto h-[24rem] w-[24rem]"
						alt="Three young kids crawling through a foam cylinder looking at camera."
					/>
				</div>

				<div>
					<img
						src={photoNinja1}
						class="m-auto h-[24rem] w-[24rem]"
						alt="Three young kids crawling through a foam cylinder looking at camera."
					/>
				</div>

				<div>
					<p class="uppercase text-red-600">About Us</p>
					<h3 class="text-lg">Our Location</h3>
					<p>
						We are proud to offer two state-of-the-art facilities in Perth. Active's two premises
						are fitted with state-of-the-art equipment, providing a safe & fun environment with
						plenty of equipment, so children are always on the move.
					</p>
					<br />
					<p>
						Our O'Connor location, located just ten minutes east of Fremantle, is a massive
						purpose-built facility spanning over 1700 sqm of space. It offers an in-ground pit and
						plenty of equipment. Our Jandakot facility, located just minutes from Cockburn Central,
						spans 1000 sqm and offers an above-ground pit.
					</p>
					<div class="mt-2" />
					<InlineButton
						buttonText="Find My Class"
						href="/dash"
						icon={ArrowNext}
						iconAlt="Navigation: Next Arrow Icon"
						iconStyle="filter: invert(25%) sepia(65%) saturate(3637%) hue-rotate(346deg) brightness(86%) contrast(100%);"
					/>
				</div>

				<div>
					<p class="uppercase text-red-600">The Sport</p>
					<h3 class="text-lg">Why Gymnastics</h3>
					<p>
						Gymnastics is more than just a sport - it's a fun and engaging way for kids to develop a
						wide range of physical and mental skills. By practicing balance, coordination, strength,
						and flexibility, children who participate in gymnastics can enhance their fine and gross
						motor skills, advance their social and intellectual abilities, and improve their body
						and spatial awareness. This, in turn, can help them succeed across a wide range of
						physical activities and sports.
					</p>
					<br />
					<p>
						Gymnastics has many significant advantages. One of the most valuable is the increased
						understanding of fundamental movement skills. Not only are these skills essential for
						healthy growth and development, but they are also an essential element in building solid
						foundations in a child’s life.
					</p>
					<div class="mt-2" />
					<InlineButton
						buttonText="Join the Active Family"
						href="/dash"
						icon={ArrowNext}
						iconAlt="Navigation: Next Arrow Icon"
						iconStyle="filter: invert(25%) sepia(65%) saturate(3637%) hue-rotate(346deg) brightness(86%) contrast(100%);"
					/>
				</div>

				<div>
					<img
						src={photoKindy1}
						class="m-auto h-[24rem] w-[24rem]"
						alt="Three young kids crawling through a foam cylinder looking at camera."
					/>
				</div>

				<div class="col-span-2 mb-8 mt-8">
					<div style="width: 100%; height: 400px;">
						<LocationMap />
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
