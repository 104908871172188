<script type="module" lang="ts">
	export const prerender = true;
	export let black = false;

	import Phone from 'iconoir/icons/phone.svg';
	import Mail from 'iconoir/icons/mail-open.svg';
	import Map from 'iconoir/icons/map-pin.svg';
</script>

<div>
	<p class="py-1 md:py-1.5">
		{#if black == true}
			<img src={Phone} alt="Phone Icon" class="mr-4 inline-block h-6 w-6" style="" /><a
				href="tel:0417503517">0417503517</a
			>
		{:else}
			<img
				src={Phone}
				alt="Phone Icon"
				class="mr-4 inline-block h-6 w-6"
				style="filter: invert(99%) sepia(100%) saturate(96%) hue-rotate(338deg) brightness(109%) contrast(100%);"
			/><a href="tel:0417503517">0417503517</a>{/if}
	</p>
	<p class="py-1 md:py-1.5">
		{#if black == true}
			<img src={Mail} alt="Phone Icon" class="mr-4 inline-block h-6 w-6" style="" /><a
				href="mailto:info@activegym.com.au">info@activegym.com.au</a
			>
		{:else}
			<img
				src={Mail}
				alt="Phone Icon"
				class="mr-4 inline-block h-6 w-6"
				style="filter: invert(99%) sepia(100%) saturate(96%) hue-rotate(338deg) brightness(109%) contrast(100%);"
			/><a href="mailto:info@activegym.com.au">info@activegym.com.au</a>
		{/if}
	</p>
	<div class="flex items-center py-1 md:py-1.5">
		{#if black == true}
			<img src={Map} alt="Phone Icon" class="mr-4 inline-block h-6 w-6" style="" />
			<p class="inline-block">
				<a href="https://g.page/ActiveGymnastics?share">u11 352 South St, O'Connor</a>
				<br />
				<a href="https://g.page/ActiveGymnastics?share">u3 30 Biscayne Way, Jandakot</a>
			</p>
		{:else}
			<img
				src={Map}
				alt="Phone Icon"
				class="mr-4 inline-block h-6 w-6"
				style="filter: invert(99%) sepia(100%) saturate(96%) hue-rotate(338deg) brightness(109%) contrast(100%);"
			/>
			<p class="inline-block">
				<a href="https://g.page/ActiveGymnastics?share">u11 352 South St, O'Connor</a>
				<br />
				<a href="https://g.page/ActiveGymnastics?share">u3 30 Biscayne Way, Jandakot</a>
			</p>
		{/if}
	</div>
</div>
